<!--
 * @Author: yangjinpeng
 * @Date: 2019-08-04 21:13:36
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-11-06 09:59:35
 -->
<template>
  <div
    class="page_box"
  >
    <div class="box_top">
      <Header class="page_hea" />
    </div>
    <div class="float-box">
      <div class="dox_con dox_conBorder">
        <div class="data_tit">
          此为系统演示，如需配置功能请下载绿色版或联系我们，联系电话4000999235
          <!-- <el-button
            @click="ExportSavePdf('哈哈哈', '')"
            type="primary"
            round
          >
            导出pdf
          </el-button> -->
        </div>
        <div class="data_con">
          <div class="list_con">
            <img
              :src="img4"
              alt=""
            >
            <div class="list_tit">
              管理员
            </div>
            <div class="list_name">
              账号：admin
              <br>
              密码：123
            </div>
            <div
              class="box_but"
              @click="goIndex('admin')"
            >
              在线体验
            </div>
          </div>
          <div class="list_con">
            <img
              :src="img1"
              alt=""
            >
            <div class="list_tit">
              基层 张小军
            </div>
            <div class="list_name">
              账号：JC01
              <br>
              密码：123
            </div>
            <div
              class="box_but"
              @click="goIndex('JC01')"
            >
              在线体验
            </div>
          </div>
          <div class="list_con">
            <img
              :src="img2"
              alt=""
            >
            <div class="list_tit">
              中层 孙国强
            </div>
            <div class="list_name">
              账号：ZC01
              <br>
              密码：123
            </div>
            <div
              class="box_but"
              @click="goIndex('ZC01')"
            >
              在线体验
            </div>
          </div>
          <div class="list_con">
            <img
              :src="img3"
              alt=""
            >
            <div class="list_tit">
              高层 刘明
            </div>
            <div class="list_name">
              账号：GC01
              <br>
              密码：123
            </div>
            <div
              class="box_but"
              @click="goIndex('GC01')"
            >
              在线体验
            </div>
          </div>
          <!-- <div class="list_con">
            <img
              style="width: 100%; height: auto"
              v-lazy="QR"
              alt=""
            >
            <div class="list_tit">
              移动APP案例展示
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- id="pdfCentent" -->
    <div
      class="float-box"
    >
      <div class="dox_con qrcode">
        <el-row>
          <el-col :span="12">
            <div
              class="data_tit"
              style="padding-top: 20px;"
            >
              <span class="titleBorder">移动APP案例展示</span>
            </div>
            <div>
              <div
                class="list_con"
              >
                <img
                  v-lazy="QR"
                  class="QRImg"
                  alt=""
                >
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div
              class="data_tit"
              style="padding-top: 20px;"
            >
              <span class="titleBorder">桌面版试用下载</span>
            </div>
            <el-row
              type="flex"
              justify="space-between"
              :gutter="20"
            >
              <el-col :span="12">
                <div
                  class="list_con img_border ios_display"
                >
                  <img
                    @click="downLoad_pc(wins_url, 'JEPaas云平台_Setup_1.0.0.exe')"
                    v-lazy="win"
                    style="width: 100px; height: auto; cursor: pointer"
                    alt=""
                  >
                </div>

                <div class="downPC">
                  <el-button
                    @click="downLoad_pc(wins_url, 'JEPaas云平台_Setup_1.0.0.exe')"
                    type="primary"
                    round
                  >
                    免费下载 (39.37MB)
                  </el-button>
                </div>
              </el-col>
              <el-col :span="12">
                <div
                  class="list_con img_border ios_display"
                >
                  <img
                    @click="mac_dialog = true"
                    v-lazy="mac_icon"
                    style="width: 100px; height: auto; cursor: pointer"
                    alt=""
                  >
                </div>
                <div class="downPC">
                  <el-button
                    @click="mac_dialog = true"
                    type="primary"
                    round
                  >
                    免费下载 (54.97MB)
                  </el-button>
                </div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </div>
      <el-dialog
        :visible.sync="mac_dialog"
        :close-on-click-modal="false"
        width="50%"
      >
        <span
          slot="title"
          class="color-3F3F3F font-size-24 font-weight-600"
        >MAC下载须知</span>
        <div class="padding-left-15">
          <p class="font-size-18 line-height-1-5">
            这里教用户如何打开这个隐藏的“<span class="font-weight-600">任何来源</span>”选项：<br>
            1、首先，我们需要先打开 <span class="font-weight-600">应用程序</span> >> <span class="font-weight-600">实用工具</span> >>
            <span class="font-weight-600"> 终端</span>，并进入界面；<br>
            2、然后我们复制以下的代码到终端中，点击“<span class="font-weight-600">回车</span>”键（输入电脑密码）
          </p>
          <p class="font-size-20 margin-top-20 font-weight-600">
            sudo spctl --master-disable
          </p>
          <div class="padding-LR-20 text-status-center">
            <img
              v-lazy="mac_1"
              style="width: 60%; height: auto;"
              alt=""
            >
          </div>
        </div>
        <div class="padding-left-15">
          <p class="font-size-18 margin-top-20 line-height-1-5">
            3、完成以上操作后，首先，我们打开
            <span class="font-weight-600">应用程序 >> 进入系统偏好设置 >> 点击安全性和隐私 >> 在通用即可看到“任何来源”</span>
            这时查看一下是否是开启状态，按上述方法开启后一般默认为开启，若未开启用户
            手动选择开启即可。
          </p>
          <div class="padding-LR-20 text-status-center">
            <img
              v-lazy="mac_2"
              style="width: 60%; height: auto;"
              alt=""
            >
          </div>
          <p class="font-size-18 margin-top-20">
            4、此时我们就能够直接下载与安装任何第三方的软件。
          </p>
        </div>
        <div class="margin-top-20">
          <el-button
            class="font-size-18 macFixedBtn"
            @click="downLoad_pc(mac_url, 'JEPaas云平台-1.0.0.dmg')"
            type="primary"
            round
          >
            免费下载 (54.97MB)
          </el-button>
        </div>
        <span
          slot="footer"
          class="dialog-footer"
        />
      </el-dialog>
    </div>
    <Footer />
  </div>
</template>

<script >
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import bg_su from '@/assets/imgs/successful/bg_su.png';
import win from '@/assets/imgs/demonstration/winDown.png';
import img1 from '@/assets/imgs/demonstration/img1.png';
import img2 from '@/assets/imgs/demonstration/img2.png';
import img3 from '@/assets/imgs/demonstration/img3.png';
import img4 from '@/assets/imgs/demonstration/img4.png';
import QR from '@/assets/newHomeImg/platform/phoneQRcode.png';
import mac_1 from '@/assets/imgs/demonstration/mac_1.png';
import mac_2 from '@/assets/imgs/demonstration/mac_2.png';
import mac_icon from '@/assets/imgs/demonstration/mac_icon.png';

const mac_url = 'http://suanbanyun-app.oss-cn-beijing.aliyuncs.com/desktop/je-ele-jepaas/1.0.0/JEPaaS%E4%BA%91%E5%B9%B3%E5%8F%B0-1.0.0.dmg';
const wins_url = 'http://suanbanyun-app.oss-cn-beijing.aliyuncs.com/desktop/je-ele-jepaas/1.0.0/JEPaaS%E4%BA%91%E5%B9%B3%E5%8F%B0_Setup_1.0.0.exe';


export default {
  name: 'SuccessfulPage',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      mac_dialog: false, // mac弹框提示信息
      mac_url,
      mac_1,
      mac_2,
      wins_url,
      mac_icon,
      bg_su,
      img1,
      img2,
      img3,
      img4,
      win,
      QR,
    };
  },
  methods: {
    goIndex() {
      // if (type === 'admin') return;
      // this.$message.success('正在升级,敬请期待!')
      window.open('http://example.jepaas.com/', 'saomiao');
    },
    downLoad_pc(url, name) {
      const tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = url;
      tempLink.download = `${name}`;
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
    },
  },
};
</script>

<style lang='less' scoped >
@import '../style/shop/util';
.page_box {
  .line-height-1-5 {
    line-height: 1.5;
  }
  .margin-top-20 {
    margin-top: 10px;
  }
  .font-size-18 {
    margin-top: 30px;
    font-size: 18px;
  }
  .box_top {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
  }
  .float-box {
    overflow: hidden;
    padding: 0 30px;
    .dox_conBorder {
      border-bottom: 2px solid #F5F5F5;
    }
    .dox_con {
      text-align: center;
      width: 1250px;
      margin: 0 auto;
      .data_tit {
        padding-top: 50px;
        font-size: 20px;
        font-weight: 600;
        color: #3f3f3f;
      }
      .data_con {
        height: 300px;
        padding: 50px 20px 80px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .list_con {
          width: 250px;
          height: 350px;
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(238, 238, 238, 1);
          box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
          text-align: center;
          margin-right: 20px;
          &:first-child {
            margin-left: 20px;
          }
          img {
            width: 100px;
            height: 100px;
            padding: 30px 0 15px 0;
          }
          .list_tit {
            height: 20px;
            font-size: 20px;
            font-weight: bold;
            color: rgba(51, 51, 51, 1);
            line-height: 24px;
          }
          .list_name {
            // height: 15px;
            font-size: 16px;
            font-weight: 400;
            color: rgba(102, 102, 102, 1);
            // line-height: 24px;
            padding: 20px 0 30px;
          }
          .box_but {
            cursor: pointer;
            display: inline-block;
            width: 180px;
            height: 50px;
            font-size: 18px;
            font-weight: 400;
            line-height: 50px;
            color: rgba(254, 255, 255, 1);
            background: rgba(255, 48, 65, 1);
            box-shadow: 2px 2px 10px 0px rgba(255, 48, 65, 0.4);
            border-radius: 25px;
            &.cancel {
              background: #cccccc;
              box-shadow: 2px 2px 10px 0px #cccccc;
              cursor: auto;
            }
            &:hover {
              background: rgba(255, 48, 65, .5);
            }
          }
        }
      }
      .zhu_con {
        padding-bottom: 100px;
        height: 17px;
        font-size: 20px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
      }
    }
    .qrcode {
      font-size: 16px;
      height: 450px;
      padding-top: 30px;
      .ios_display {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .ios_border {
        display: block;
        width: 110px;
        height: 110px;
        background: #B4B4B4;
        text-align: center;
        margin: 0 auto;
        line-height: 110px;
        border-radius: 50%;
        i {
          font-size: 75px!important;
          color: white!important;
        }
      }
      .img_border {
        font-size: 50px;
        width: 200px;
        height: 200px;
        line-height: 200px;
        margin: 0 auto;
        text-align: center;
        i {
          font-size: 100px;
          color: #1296db;
        }
      }
      .titleBorder {
        height: 25px;
        line-height: 25px;
        position: relative;
        &::before {
          content: '——';
          position: absolute;
          top: 50%;
          width: 50px;
          left: -55px;
          margin-top: -15px;
        }
        &::after {
          content: '——';
          position: absolute;
          top: 50%;
          width: 50px;
          right: -55px;
          margin-top: -15px;
        }
      }
      .QRImg {
        width: 250px ;
        height: auto;
        padding-bottom: 0;
        padding-top: 25px;
      }
    }
  }

}
</style>
<style lang="less">
.page_box {
  .macFixedBtn {
    position: fixed;
    bottom: 20px;
    left: 50%;
    width: 200px;
    margin-left: -100px;
  }
  .el-dialog {
    padding-bottom: 50px;
  }
  .el-dialog__body {
      padding-top: 0!important;
   }
  .downPC {
    margin-top: 20px;
    .el-button {
      font-size: 18px;
      // background: #6FB3EA;
      // border-color: #6FB3EA;
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>
